import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import axios from "axios";

axios.defaults.baseURL = "https://motoexpert.online:3073/";
// axios.defaults.baseURL = "http://localhost:3073/";

console.log("Node mode:", process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://motoexpert.online:3073/";
}

axios.defaults.headers.common["Authorization"] =
  "Bearer " + sessionStorage.getItem("token");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
