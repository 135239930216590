import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser } from "../../utils/AuthDataUtils";

import { Col, Row } from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomModal from "../../components/CustomModal";
import UserCard from "../../components/UserCard";
import LoadingScreen from "../../components/LoadingScreen";

export default function UserDetails() {
  const currentUser = loadUser();
  console.log("Render UserDetails, user:", currentUser !== null);

  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .get("user/" + location.state.phone)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        error(parseAxiosError(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location]);

  if (loading) {
    return <LoadingScreen />;
  }

  const toggleRemoveModal = () => setRemoveModal(!removeModal);

  const error = (message) => {
    setMessage(message);
  };

  const onDeleteConfirm = () => {
    console.log("UserDetails onDeleteConfirm");
    toggleRemoveModal();
    axios
      .delete("user/" + user.phone)
      .then(() => {
        navigate("/users");
      })
      .catch((err) => {
        error(parseAxiosError(err));
      });
  };

  const removeModalBody = (
    <>
      <div>
        <b>Czy napewno chcesz usunąć konto {user.phone}?</b>
      </div>
      Spododuje to nieodwracalne usunięcie wszystkich danych związanych z tym
      kontem!
    </>
  );

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Row className="mt-2" />
        <CustomAlert
          message={message}
          color="danger"
          dismiss={() => setMessage("")}
        />
        <CustomModal
          isOpen={removeModal}
          toggle={toggleRemoveModal}
          title={"Usuwanie konta"}
          body={removeModalBody}
          onConfirm={onDeleteConfirm}
          onConfirmColor={"danger"}
          onConfirmText={"Usuń"}
        />
        <UserCard user={user} target={"/users"} onDelete={toggleRemoveModal} />
      </Row>
    </Col>
  );
}
