import React from "react";
import { Col, Row } from "reactstrap";

export default function Terms() {
  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">Warunki prawne korzystania z serwisu...</Row>
    </Col>
  );
}
