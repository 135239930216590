import React from "react";
import { Table } from "reactstrap";

import LoadingPanel from "./LoadingPanel";

export default function CustomTable({ loading, data, layout, onRowClick }) {
  const EmptyLabel = () => {
    if (loading) {
      return <LoadingPanel size={60} />;
    }
    if (!data.length) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: 20,
          }}
        >
          Brak wyników
        </div>
      );
    }
  };

  const header = layout.map((col, index) => {
    return <th key={index}>{col.name}</th>;
  });

  const rows = data.map((item, index) => {
    const body = layout.map((col, idx) => {
      const content = col.key(item);
      if (col.width) return <td className={"w-" + col.width}>{content}</td>;
      return <td key={idx}>{content}</td>;
    });
    return (
      <tr key={index} onClick={() => onRowClick(index)}>
        {body}
      </tr>
    );
  });

  const Body = () => {
    if (loading) {
      return;
    }
    return <tbody>{rows}</tbody>;
  };

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>{header}</tr>
        </thead>
        <Body />
      </Table>
      <EmptyLabel />
    </>
  );
}
