import axios from "axios";
import { attemptTokenRefresh, clearAuthData } from "./AuthDataUtils";

export function parseAxiosError(error) {
  console.log("Axios error", error);
  if (error.response) {
    if (error.response.data) {
      const data = error.response.data;
      const message = data.message
        ? translateErrorMessage(data.message)
        : data.error;
      return message + " (" + error.response.status + ")";
    } else if (error.response.status < 500) {
      return "Serwer nie przyjął żądania (" + error.response.status + ")";
    } else {
      return "Błąd serwera (" + error.response.status + ")";
    }
  } else if (error.request) {
    return "Brak połączenia. Spróbuj ponownie później...";
  } else {
    console.warn("Error", error.message);
    return "Ups coś poszło nie tak...";
  }
}

function translateErrorMessage(message) {
  if (message.includes("Incorrect phone or password"))
    return "Nieprawidłowy numer telefonu lub hasło";
  if (message.includes("User already exists")) return "Użytkownik już istnieje";
  if (message.includes("Phone number not found"))
    return "Numer telefonu nie odnaleziony";
  if (message.includes("Verify your phone before login"))
    return "Zweryfikuj swój numer telefonu";
  if (message.includes("Bad code")) return "Kod niepoprawny";
  if (message.includes("Code expired")) return "Kod stracił ważność";
  if (message.includes("Not an employee")) return "To nie pracownik";
  if (message.includes("Forbidden")) return "Brak uprawnień";
  return message;
}

export function setupAxiosInterceptor(faliureCallback) {
  if (axios.interceptors.response.handlers.length > 0) {
    return;
  }
  console.log("Setup axios interceptor");
  const acceptRefresh = (url) => {
    return url !== "login" && url !== "login/refresh";
  };
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      const response = error.response;
      const url = error.config.url;
      if (response && response.status === 401 && acceptRefresh(url)) {
        try {
          const token = await attemptTokenRefresh();
          error.config.headers["Authorization"] = "Bearer " + token;
          console.log(
            "Reattempting call",
            error.config.method.toUpperCase(),
            error.config.url
          );
          return axios(error.config);
        } catch (err) {
          console.log("Token refresh failed", err);
          clearAuthData();
          faliureCallback();
        }
      }
      return Promise.reject(error);
    }
  );
}

export async function loadOrdersWithUsers(query) {
  console.log("loadOrdersWithUsers", query);
  let page = (await axios.get("order/all?" + query)).data;

  const orders = page.content;
  const cli = orders.map((o) => o.client);
  const sel = orders.map((o) => o.seller).filter((u) => u);
  const toGet = Array.from(new Set(cli.concat(sel)));

  const promises = toGet.map((e) => axios.get("user/" + e));
  const users = (await Promise.all(promises)).map((e) => e.data);

  const findUser = (phone) => {
    if (!phone) {
      return "empty";
    }
    return users.find((u) => u.phone === phone);
  };

  page.content = orders.map((o) => ({
    ...o,
    sellerData: findUser(o.seller),
    clientData: findUser(o.client),
  }));
  return page;
}
