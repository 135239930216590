import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomButton from "../../components/CustomButton";

export default function ForgotPassword() {
  console.log("Render ForgotPassword");

  const location = useLocation();
  const navigate = useNavigate();

  const [phone, setPhone] = useState(location.state.phone);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onReset = () => {
    console.log("ForgotPassword onReset");

    if (!phone) {
      error("Brakuje numeru telefonu!");
      return;
    }

    const data = {
      phone: phone,
    };

    setMessage("");
    setLoading(true);
    axios
      .post("reset", data)
      .then(() => navigate("/reset-confirm", { state: { phone: phone } }))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <CardBody>
            <CardTitle tag="h5">Reset hasła</CardTitle>
            <Form>
              <FormGroup>
                <Label for="phone">Numer telefonu</Label>
                <Input
                  type="phone"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Row className="align-items-center viewport-height">
              <Col></Col>
              <Col xs="auto">
              <CustomButton
                  text={"Wyślij kod"}
                  color="primary"
                  onClick={onReset}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Col>
  );
}
