import React, { useState } from "react";
import { Link } from "react-router-dom";

import { loadUser } from "../utils/AuthDataUtils";

import { BrowserView, MobileView } from "react-device-detect";

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
} from "reactstrap";
import { initialPath, isMarketer, isSeller } from "../utils/AuthoritiesUtils";

export default function NavigationBar({ onLoggedOut }) {
  const user = loadUser();
  console.log("Render NavigationBar, user:", user !== null);

  const [isOpen, setIsOpen] = useState(false);

  const onLogout = () => {
    console.log("NavigationBar onLogout");
    close();
    onLoggedOut();
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const CustomNavItem = ({ to, text, onClick, condition }) => {
    if (!condition) {
      return;
    }
    return (
      <NavItem>
        <NavLink tag={Link} to={to} onClick={onClick}>
          {text}
        </NavLink>
      </NavItem>
    );
  };

  let brand;
  if (user) {
    brand = (
      <NavbarBrand tag={Link} to={initialPath(user)} onClick={close}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          height="40"
          alt="DIAGME"
        />
      </NavbarBrand>
    );
  } else {
    brand = (
      <NavbarBrand tag={Link} to="/" onClick={close}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          height="40"
          alt="DIAGME"
        />
      </NavbarBrand>
    );
  }

  let main;
  if (user) {
    main = (
      <Nav className="ml" navbar>
        <CustomNavItem
          to="/orders"
          text="Zamówienia"
          onClick={close}
          condition={isSeller(user)}
        />
        <CustomNavItem
          to="/users"
          text="Użytkownicy"
          onClick={close}
          condition={true}
        />
        <CustomNavItem
          to="/adverts"
          text="Reklamy"
          onClick={close}
          condition={isMarketer(user)}
        />
      </Nav>
    );
  } else {
    main = <Nav className="ml" navbar></Nav>;
  }

  let control;
  if (user) {
    control = (
      <Nav className="ms-auto" navbar>
        <CustomNavItem
          to="/profile"
          text="Profil"
          onClick={close}
          condition={true}
        />
        <CustomNavItem
          to="/"
          text="Wyloguj"
          onClick={onLogout}
          condition={true}
        />
      </Nav>
    );
  } else {
    control = <Nav className="ms-auto" navbar></Nav>;
  }

  return (
    <>
      <MobileView>
        <Navbar color="light" light expand="lg">
          {brand}
          <NavbarToggler onClick={toggle} />
          <Collapse navbar isOpen={isOpen}>
            {main}
            {control}
          </Collapse>
        </Navbar>
      </MobileView>
      <BrowserView>
        <Navbar color="light" light expand="md">
          {brand}
          {main}
          {control}
        </Navbar>
      </BrowserView>
    </>
  );
}
