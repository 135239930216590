import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser } from "../../utils/AuthDataUtils";
import { initialPath } from "../../utils/AuthoritiesUtils";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomButton from "../../components/CustomButton";

export default function Login({ onLoggedIn }) {
  const user = loadUser();
  console.log("Render Login, user:", user !== null);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onLogin = () => {
    console.log("Login onLogin");

    if (!phone) {
      error("Brakuje numeru telefonu!");
      return;
    }
    if (!password) {
      error("Brakuje hasła!");
      return;
    }

    const data = {
      phone: phone,
      password: password,
    };

    setMessage("");
    setLoading(true);
    axios
      .post("login", data)
      .then((res) =>
        onLoggedIn(res.data.user, res.data.token, res.data.refreshToken)
      )
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  if (user) {
    return <Navigate to={initialPath(user)} />;
  }

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <CardBody>
            <CardTitle tag="h5">Logowanie</CardTitle>
            <Form>
              <FormGroup>
                <Label for="phone">Numer telefonu</Label>
                <Input
                  type="phone"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Hasło</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Row className="align-items-center viewport-height">
              <Col>
                <Link to="/reset" state={{ phone: phone }}>
                  Zapomniałem hasła
                </Link>
              </Col>
              <Col xs="auto">
                <CustomButton
                  text={"Zaloguj"}
                  color="primary"
                  onClick={onLogin}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Col>
  );
}
