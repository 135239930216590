import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser } from "../../utils/AuthDataUtils";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomButton from "../../components/CustomButton";

export default function PhoneConfirm({ onLoggedIn }) {
  const user = loadUser();
  console.log("Render PhoneConfirm, user:", user !== null);

  const location = useLocation();

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onNext = () => {
    console.log("PhoneConfirm onNext");

    if (!code.match("^[0-9]{6,6}$")) {
      error("Kod musi składać się z 6 cyfr bez odstępów!");
      return;
    }

    const data = {
      phone: location.state.phone,
      code: code,
    };

    setMessage("");
    setLoading(true);
    axios
      .post("reset/confirm", data)
      .then((res) =>
        onLoggedIn(res.data.user, res.data.token, res.data.refreshToken)
      )
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  if (user) {
    return <Navigate to={"/update-password"} />;
  }

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <CardBody>
            <CardTitle tag="h5">Potwierdź numer telefonu</CardTitle>
            <Form>
              <FormGroup>
                <Label for="phone">Kod SMS</Label>
                <Input
                  type="numeric"
                  name="code"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Row className="align-items-center viewport-height">
              <Col></Col>
              <Col xs="auto">
                <CustomButton
                  text={"Potwierdź"}
                  color="primary"
                  onClick={onNext}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Col>
  );
}
