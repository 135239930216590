import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { loadUser } from "../../utils/AuthDataUtils";
import { loadOrdersWithUsers } from "../../utils/AxiosUtils";
import {
  dateStringToStringAgo,
  formatPhoneString,
  labelToPriority,
  optionToLabel,
} from "../../utils/StringUtils";

import { Col, Row } from "reactstrap";
import CustomTable from "../../components/CustomTable";

export default function Dashboard() {
  const user = loadUser();
  console.log("Render Dashboard, user:", user !== null);

  const navigate = useNavigate();

  const clearTimerRef = useRef();

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const sortOrders = (orders) => {
      return orders.sort((a, b) => {
        const prioA = labelToPriority(a.clientData.label);
        const prioB = labelToPriority(b.clientData.label);
        return prioA > prioB || new Date(a.createdAt) - new Date(b.createdAt);
      });
    };

    const reload = () => {
      console.log("Dashboard reload");
      const querry = "page=0&status=NEW";
      loadOrdersWithUsers(querry)
        .then((page) => setOrders(sortOrders(page.content)))
        .catch((err) => console.warn(err))
        .finally(() => {
          clearTimerRef.current = setTimeout(reload, 10000);
        });
    };

    reload();
    return () => clearTimeout(clearTimerRef.current);
  }, []);

  const onRowClick = (index) => {
    console.log("Dashboard onRowClick", index);
    navigate("/order-details", { state: { id: orders[index].id } });
  };

  const renderClient = (order) => {
    const client = order.clientData;
    if (client.name) {
      return client.name;
    }
    return formatPhoneString(client.phone);
  };

  const truncateString = (string) => {
    const maxLength = 32;
    if (string.length > maxLength) {
      return string.substring(0, maxLength - 3) + "...";
    }
    return string;
  };

  const tableLayout = [
    { name: "Klient", key: (o) => renderClient(o) },
    { name: "Etykieta", key: (o) => optionToLabel(o.clientData.label) },
    { name: "Opis", key: (o) => truncateString(o.description) },
    { name: "Kiedy", key: (o) => dateStringToStringAgo(o.createdAt) },
  ];

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }}>
      <Row className="mt-lg-3">
        <CustomTable
          data={orders}
          layout={tableLayout}
          onRowClick={onRowClick}
        />
      </Row>
    </Col>
  );
}
