import axios from "axios";

export function storeAuthData(user, token, refreshToken) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("refreshToken", refreshToken);
}

export function clearAuthData() {
  delete axios.defaults.headers.common["Authorization"];
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  localStorage.removeItem("refreshToken");
}

export function loadUser() {
  return JSON.parse(sessionStorage.getItem("user"));
}

export function updateUser(user) {
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function loadToken() {
  return localStorage.getItem("token");
}

export function loadRefreshToken() {
  return localStorage.getItem("refreshToken");
}

export async function attemptTokenRefresh() {
  const refreshToken = loadRefreshToken();
  if (!refreshToken) {
    throw new Error("No token found");
  }

  console.log("Refresh token found, attepting authorization");
  const axiosInstance = axios.create();
  delete axiosInstance.defaults.headers.common["Authorization"];

  try {
    const res = await axiosInstance.post("login/refresh", {
      refreshToken: refreshToken,
    });
    console.log("Refresh token success");
    storeAuthData(res.data.user, res.data.token, res.data.refreshToken);
    return res.data.token;
  } catch (err) {
    clearAuthData();
    throw new Error("Refresh token rejected by server");
  }
}
