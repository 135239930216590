import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function range(size, startAt) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

function toWindows(inputArray, size) {
  return Array.from({ length: inputArray.length - (size - 1) }, (_, index) =>
    inputArray.slice(index, index + size)
  );
}

export default function CustomPagination({
  visible,
  current,
  total,
  onChange,
}) {
  if (!visible) {
    return;
  }
  const maxPages = 5;

  const generatePages = () => {
    const all = range(total, 1);
    if (total < maxPages) {
      return all;
    }
    const windows = toWindows(all, maxPages);
    if (current < maxPages / 2) {
      return windows.at(0);
    }
    if (current > total - 1 - maxPages / 2) {
      return windows.at(-1);
    }
    return windows.at(current + 1 - Math.round(maxPages / 2));
  };

  const items = generatePages().map((n, index) => {
    return (
      <PaginationItem key={index} active={current === n - 1}>
        <PaginationLink
          disabled={current === n - 1}
          onClick={() => onChange(n - 1)}
        >
          {n}
        </PaginationLink>
      </PaginationItem>
    );
  });

  return (
    <Pagination
      aria-label="Page navigation"
      listClassName="justify-content-center"
    >
      <PaginationItem>
        <PaginationLink
          disabled={current === 0}
          previous
          onClick={() => onChange(current - 1)}
        />
      </PaginationItem>
      {items}
      <PaginationItem>
        <PaginationLink
          disabled={current === total - 1}
          next
          onClick={() => onChange(current + 1)}
        />
      </PaginationItem>
    </Pagination>
  );
}
