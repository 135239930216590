import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { loadOrdersWithUsers, parseAxiosError } from "../../utils/AxiosUtils";
import { loadUser } from "../../utils/AuthDataUtils";
import {
  dateStringToStringAgo,
  formatPhoneString,
  inlineReturn,
  optionsToStatuses,
  parseOrderStatus,
  statusOptions,
} from "../../utils/StringUtils";

import { Col, Input, Row } from "reactstrap";
import Select from "react-select";

import CustomAlert from "../../components/CustomAlert";
import CustomTable from "../../components/CustomTable";
import CustomPagination from "../../components/CustomPagination";

export default function Orders() {
  const user = loadUser();
  console.log("Render Orders, user:", user !== null);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [searchLock, setSearchLock] = useState("");
  const [statues, setStatuses] = useState([]);
  const [statuesLock, setStatuesLock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const loadFirstPage = useCallback(
    (onSuccess) => {
      let params = ["page=0"];
      if (search && search.length) {
        params.push("key=" + search);
      }
      if (statues && statues.length) {
        params.push("status=" + statues.join(","));
      }
      setSearchLock(search);
      setStatuesLock(statues);
      setPage(0);
      setLoading(true);
      loadOrdersWithUsers(params.join("&"))
        .then((res) => onSuccess(res))
        .catch((err) => error(parseAxiosError(err)))
        .finally(() => setLoading(false));
    },
    [search, statues]
  );

  const handlePageLoaded = (newPage) => {
    console.log("Orders handleNewPage", newPage);
    setOrders(newPage.content);
    setTotalPages(newPage.totalPages);
  };

  useEffect(() => loadFirstPage(handlePageLoaded), [loadFirstPage]);

  const onPageChange = (number) => {
    console.log("Orders onPageChange", number);
    let params = ["page=" + number];
    if (searchLock && searchLock.length) {
      params.push("key=" + searchLock);
    }
    if (statuesLock && statuesLock.length) {
      params.push("status=" + statuesLock.join(","));
    }
    setPage(number);
    setLoading(true);
    loadOrdersWithUsers(params.join("&"))
      .then((res) => handlePageLoaded(res))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  const onRowClick = (index) => {
    console.log("Orders onRowClick", index);
    navigate("/order-details", { state: { id: orders[index].id } });
  };

  const onStatusesChange = (newValue) => {
    setStatuses(optionsToStatuses(newValue));
  };

  const renderClient = (order) => {
    const client = order.clientData;
    if (client.name) {
      return client.name;
    }
    return formatPhoneString(client.phone);
  };

  const renderSeller = (order) => {
    if (!order.seller) {
      return;
    }
    const seller = order.sellerData;
    if (seller.name) {
      return seller.name;
    }
    return formatPhoneString(seller.phone);
  };

  const tableLayout = [
    { name: "Nr", key: (o) => inlineReturn(o.number) },
    { name: "Status", key: (o) => parseOrderStatus(o.status) },
    { name: "Klient", key: (o) => renderClient(o) },
    { name: "Sprzedawca", key: (o) => renderSeller(o) },
    { name: "Kiedy", key: (o) => dateStringToStringAgo(o.createdAt) },
  ];

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }}>
      <Row className="mt-lg-3">
        <CustomAlert
          message={message}
          color="danger"
          dismiss={() => setMessage("")}
        />
        <Row className="mt-lg-3">
          <Col>
            <Input
              type="text"
              placeholder={"Fragment nr tel. klienta albo opisu"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-lg-3">
          <Select
            isMulti
            options={statusOptions()}
            onChange={onStatusesChange}
            placeholder={"Status zamówienia"}
          />
        </Row>
        <Row className="mt-lg-3">
          <CustomTable
            loading={loading}
            data={orders}
            layout={tableLayout}
            onRowClick={onRowClick}
          />
        </Row>
        <Row>
          <CustomPagination
            visible={!loading && orders.length}
            current={page}
            total={totalPages}
            onChange={onPageChange}
          />
        </Row>
      </Row>
    </Col>
  );
}
