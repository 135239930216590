import { Navigate, Route, Routes } from "react-router-dom";

import { loadUser } from "../utils/AuthDataUtils";

import Login from "../screens/guest/Login";
import Terms from "../screens/guest/Terms";
import ForgotPassword from "../screens/guest/ForgotPassword";
import Orders from "../screens/user/Orders";
import Users from "../screens/user/Users";
import Profile from "../screens/user/Profile";
import PhoneConfirm from "../screens/guest/PhoneConfirm";
import UserPassword from "../screens/user/UserPassword";
import UserUpdate from "../screens/user/UserUpdate";
import OrderDetails from "../screens/user/OrderDetails";
import UserDetails from "../screens/user/UserDetails";
import UserNew from "../screens/user/UserNew";
import Adverts from "../screens/user/Adverts";
import Dashboard from "../screens/user/Dashboard";

export default function Navigation({ onLoggedIn, onLoggedOut }) {
  const user = loadUser();
  console.log("Render Navigation, user:", user !== null);

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  return (
    <Routes>
      <Route exact path="/" element={<Login onLoggedIn={onLoggedIn} />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/reset" element={<ForgotPassword />} />
      <Route
        exact
        path="/reset-confirm"
        element={<PhoneConfirm onLoggedIn={onLoggedIn} />}
      />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute user={user}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/orders"
        element={
          <ProtectedRoute user={user}>
            <Orders />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/order-details"
        element={
          <ProtectedRoute user={user}>
            <OrderDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/adverts"
        element={
          <ProtectedRoute user={user}>
            <Adverts />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users"
        element={
          <ProtectedRoute user={user}>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/user-details"
        element={
          <ProtectedRoute user={user}>
            <UserDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/user-new"
        element={
          <ProtectedRoute user={user}>
            <UserNew />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <ProtectedRoute user={user}>
            <Profile onLoggedOut={onLoggedOut} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/user-update"
        element={
          <ProtectedRoute user={user}>
            <UserUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/user-password"
        element={
          <ProtectedRoute user={user}>
            <UserPassword />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
