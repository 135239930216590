import React from "react";
import { HashLoader } from "react-spinners";

export default function LoadingScreen() {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <HashLoader color="#FB6B2D" size={75} />
    </div>
  );
}
