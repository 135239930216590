import React from "react";
import { HashLoader } from "react-spinners";

export default function LoadingPanel({ size = 30 }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <HashLoader color="#FB6B2D" size={size} />
    </div>
  );
}
