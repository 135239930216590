import React, { useState } from "react";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser } from "../../utils/AuthDataUtils";

import { Col, Row } from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomModal from "../../components/CustomModal";
import UserCard from "../../components/UserCard";

export default function Profile({ onLoggedOut }) {
  const user = loadUser();
  console.log("Render UserDetails, user:", user !== null);

  const [removeModal, setRemoveModal] = useState(false);
  const [message, setMessage] = useState("");

  const toggleRemoveModal = () => setRemoveModal(!removeModal);

  const error = (message) => {
    setMessage(message);
  };

  const onDeleteConfirm = () => {
    console.log("Profile onDeleteConfirm");
    toggleRemoveModal();
    axios
      .delete("user")
      .then(() => {
        onLoggedOut();
      })
      .catch((err) => {
        error(parseAxiosError(err));
      });
  };

  const removeModalBody = (
    <>
      <div>
        <b>Czy napewno chcesz usunąć konto {user.phone}?</b>
      </div>
      Spododuje to nieodwracalne usunięcie wszystkich danych związanych z tym
      kontem!
    </>
  );

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Row className="mt-2" />
        <CustomAlert
          message={message}
          color="danger"
          dismiss={() => setMessage("")}
        />
        <CustomModal
          isOpen={removeModal}
          toggle={toggleRemoveModal}
          title={"Usuwanie konta"}
          body={removeModalBody}
          onConfirm={onDeleteConfirm}
          onConfirmColor={"danger"}
          onConfirmText={"Usuń"}
        />
        <UserCard
          user={user}
          target={"/profile"}
          onDelete={toggleRemoveModal}
        />
      </Row>
    </Col>
  );
}
