import React from "react";
import { Button, Spinner } from "reactstrap";

export default function ButtonWithLoading({ text, color, onClick, loading }) {
  if (loading) {
    // TODO this is shit, but works :D
    const dummy = "x".repeat(text.length / 2);
    return (
      <Button color="primary" disabled>
        <span style={{ color: "#0000" }}>{dummy}</span>
        <Spinner size="sm" />
        <span style={{ color: "#0000" }}>{dummy}</span>
      </Button>
    );
  }

  return (
    <Button disabled={loading} color={color} onClick={onClick}>
      {text}
    </Button>
  );
}
