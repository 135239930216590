import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";
import { loadUser } from "../../utils/AuthDataUtils";
import { isEmployee, isManager, isSeller } from "../../utils/AuthoritiesUtils";
import {
  dateStringToStringAgo,
  formatPhoneString,
  inlineReturn,
  optionToLabel,
} from "../../utils/StringUtils";

import { Col, Input, Row, Button } from "reactstrap";
import CustomAlert from "../../components/CustomAlert";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../components/CustomPagination";

export default function Users() {
  const user = loadUser();
  console.log("Render Users, user:", user !== null);

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLock, setSearchLock] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const fetchData = useCallback(() => {
    const url = "user/all?page=0&key=" + search;
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setPage(0);
        setSearchLock(search);
        setUsers(res.data.content);
      })
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  }, [search]);

  useEffect(() => fetchData(), [fetchData]);

  const onPageChange = (number) => {
    console.log("Users onPageChange", number);
    fetchData("user/all?page=" + page + "key=" + searchLock);
  };

  const onNew = () => {
    console.log("Users onNew");
    navigate("/user-new");
  };

  const onRowClick = (index) => {
    console.log("Users onRowClick", index);
    navigate("/user-details", { state: { phone: users[index].phone } });
  };

  const employeeString = (user) => {
    if (isEmployee(user)) return "Pracownik";
    return "Klient";
  };

  const labelString = (user) => {
    if (isEmployee(user)) return "";
    return optionToLabel(user.label);
  };

  const tableLayout = [
    { name: "Telefon", key: (u) => formatPhoneString(u.phone) },
    { name: "Rola", key: (u) => employeeString(u) },
    { name: "Etykieta", key: (u) => labelString(u) },
    { name: "Imię i nazwisko", key: (u) => inlineReturn(u.name) },
    { name: "Utworzony", key: (u) => dateStringToStringAgo(u.createdAt) },
  ];

  const AddButton = () => {
    if (!isManager(user) && !isSeller(user)) {
      return;
    }
    return (
      <Col xs="auto">
        <Button color="secondary" onClick={onNew}>
          Dodaj nowego
        </Button>
      </Col>
    );
  };

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }}>
      <Row className="mt-lg-3">
        <CustomAlert
          message={message}
          color="danger"
          dismiss={() => setMessage("")}
        />
        <Row className="mt-lg-3">
          <Col>
            <Input
              type="text"
              placeholder={"Fragment nr tel. albo imienia i nazwiska"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <AddButton />
        </Row>
        <Row className="mt-lg-3">
          <CustomTable
            loading={loading}
            data={users}
            layout={tableLayout}
            onRowClick={onRowClick}
          />
        </Row>
        <Row>
          <CustomPagination
            visible={!loading && users.length}
            current={page}
            total={totalPages}
            onChange={onPageChange}
          />
        </Row>
      </Row>
    </Col>
  );
}
