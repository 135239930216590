export function inlineReturn(string) {
  return string;
}

export function stringOrEmpty(str) {
  return str ? str : "";
}

export function dateToString(d) {
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2)
  );
}

export function dateToStringAgo(date) {
  const now = new Date();
  const seconds = (now - date.getTime()) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  if (minutes < 1) return "Teraz";
  if (hours < 1) return parseInt(minutes).toString() + " min. temu";
  if (hours < 24) return parseInt(hours).toString() + " godz. temu";
  return dateToString(date);
}

export function dateStringToStringAgo(dateString) {
  const date = new Date(dateString);
  return dateToStringAgo(date);
}

export function formatPhoneString(phone) {
  phone = phone.slice(0, 3) + " " + phone.slice(3);
  phone = phone.slice(0, 7) + " " + phone.slice(7);
  phone = phone.slice(0, 11) + " " + phone.slice(11);
  return phone;
}

export function statusOptions() {
  return [
    { value: "NEW", label: "Nowe" },
    { value: "PROCESSING", label: "W realizacji" },
    { value: "CLOSED", label: "Zamknięte" },
  ];
}

export function optionsToStatuses(options) {
  return options.map((e) => e.value);
}

export function parseOrderStatus(status) {
  if (status === "NEW") return "Nowe";
  if (status === "PROCESSING") return "W realizacji";
  if (status === "CLOSED") return "Zamknięte";
}

export function labelOptions() {
  return [
    { value: "WHOLESALER", label: "Hurtownik" },
    { value: "RETAILER", label: "Detalista" },
  ];
}

export function optionToLabel(option) {
  if (!option) return null;
  if (option === "WHOLESALER") return "Hurtownik";
  if (option === "RETAILER") return "Detalista";
}

export function labelToOption(label) {
  const options = labelOptions();
  return options.filter((opt) => {
    return opt.value === label;
  });
}

export function labelToPriority(label) {
  if (label === "WHOLESALER") return 1;
  return 2; // RETAILER or none
}
