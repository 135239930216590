export function authoritiesOptions() {
  return [
    { value: "ROLE_MANAGER", label: "Manager" },
    { value: "ROLE_SELLER", label: "Sprzedawca" },
    { value: "ROLE_MARKETER", label: "Marketingowiec" },
    { value: "ROLE_CLIENT", label: "Klient" },
  ];
}

export function optionsToAuthorities(options) {
  return options.map((e) => {
    return { authority: e.value };
  });
}

export function authoritiesToOptions(authorities) {
  const opts = authoritiesOptions();
  const auth = authorities.map((a) => a.authority);
  return opts.filter((o) => auth.includes(o.value));
}

export function isManager(user) {
  return user.authorities.map((a) => a.authority).includes("ROLE_MANAGER");
}

export function isSeller(user) {
  return user.authorities.map((a) => a.authority).includes("ROLE_SELLER");
}

export function isMarketer(user) {
  return user.authorities.map((a) => a.authority).includes("ROLE_MARKETER");
}

export function isClient(user) {
  return user.authorities.map((a) => a.authority).includes("ROLE_CLIENT");
}

export function isEmployee(user) {
  return isManager(user) || isSeller(user) || isMarketer(user);
}

export function initialPath(user) {
  if (isSeller(user)) {
    return "/dashboard";
  }
  return "/users";
}
