import React from "react";
import { Alert } from "reactstrap";

export default function CustomAlert({ message, color, dismiss }) {
  return (
    <Alert color={color} isOpen={message ? true : false} toggle={dismiss}>
      {message}
    </Alert>
  );
}
