import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

export default function CustomModal({
  isOpen,
  toggle,
  title,
  body,
  onConfirm,
  onConfirmColor,
  onConfirmText,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color={onConfirmColor} onClick={onConfirm}>
          {onConfirmText}
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  );
}
