import "react-image-gallery/styles/css/image-gallery.css";

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";
import { loadUser } from "../../utils/AuthDataUtils";
import {
  dateStringToStringAgo,
  formatPhoneString,
  optionToLabel,
  parseOrderStatus,
} from "../../utils/StringUtils";

import { Col, Row, ListGroup, ListGroupItem, Input } from "reactstrap";
import ImageGallery from "react-image-gallery";

import CustomAlert from "../../components/CustomAlert";
import LoadingScreen from "../../components/LoadingScreen";
import CustomModal from "../../components/CustomModal";
import CustomButton from "../../components/CustomButton";

export default function OrderDetails() {
  const user = loadUser();
  console.log("Render OrderDetails, user:", user !== null);

  const location = useLocation();
  const id = location.state.id;

  const commentRef = useRef();

  const [order, setOrder] = useState([]);
  const [images, setImages] = useState([]);
  const [seller, setSeller] = useState();
  const [client, setClient] = useState();
  const [comment, setComment] = useState();
  const [modal, setModal] = useState({ isOpen: false });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    commentRef.current = comment;
  }, [comment]);

  useEffect(() => {
    const loadUserData = async (user) => {
      return axios.get("user/" + user);
    };

    const loadUsers = (order) => {
      const userPromises = [];
      if (order.seller) {
        userPromises.push(
          loadUserData(order.seller)
            .then((res) => setSeller(res.data))
            .catch(console.log)
        );
      }
      if (order.client) {
        userPromises.push(
          loadUserData(order.client)
            .then((res) => setClient(res.data))
            .catch(console.log)
        );
      }
      const imagePromises = order.images.map(async (i) => {
        const res = await fetch(i);
        const blob = await res.blob();
        return {
          original: URL.createObjectURL(blob),
          thumbnail: URL.createObjectURL(blob),
        };
      });
      Promise.all(userPromises)
        .then(() => Promise.all(imagePromises))
        .then((imgs) => {
          setOrder(order);
          setImages(imgs);
        })
        .finally(() => setLoading(false));
    };

    axios
      .get("order/" + id)
      .then((res) => loadUsers(res.data))
      .catch((err) => error(parseAxiosError(err)));
  }, [id]);

  if (loading) {
    return <LoadingScreen />;
  }

  const closeModal = () => {
    setModal({
      isOpen: false,
    });
  };

  const error = (message) => {
    setMessage(message);
    setLoading(false);
  };

  const markerIfNull = (text) => {
    if (!text) {
      return <i>Nie ustawione</i>;
    }
    return text;
  };

  const userElement = (user) => {
    if (!user) {
      return <i>Nie ustawione</i>;
    }
    return (
      <Link to={"/user-details"} state={{ phone: user.phone }}>
        {markerIfNull(user.name)}
      </Link>
    );
  };

  const onAssignConfirm = () => {
    console.log("OrderDetails onAssignConfirm");
    closeModal();
    order.seller = user.phone;
    order.status = "PROCESSING";
    order.images = [".."]; // it will be ignored anyway
    setLoading(true);
    axios
      .put("order/" + order.id, order)
      .then((res) => {
        setOrder(res.data);
        setSeller(user);
        setLoading(false);
      })
      .catch((err) => {
        error(parseAxiosError(err));
      });
  };

  const onAssign = () => {
    console.log("OrderDetails onAssign");
    setModal({
      isOpen: true,
      title: "Przypisanie",
      body: "Czy napewno chcesz się przypisać do tego zamówienia?",
      onConfirm: onAssignConfirm,
      onConfirmText: "Przypisz mnie",
      onConfirmColor: "primary",
    });
  };

  const onCloseConfirm = () => {
    console.log("OrderDetails onCloseConfirm");
    closeModal();
    order.seller = user.phone;
    order.status = "CLOSED";
    order.images = [".."]; // it will be ignored anyway
    setLoading(true);
    axios
      .put("order/" + order.id, order)
      .then((res) => {
        setOrder(res.data);
        setLoading(false);
      })
      .catch((err) => {
        error(parseAxiosError(err));
      });
  };

  const onClose = () => {
    console.log("OrderDetails onClose");
    setModal({
      isOpen: true,
      title: "Zakończenie",
      body: "Czy napewno chcesz się przypisać do tego zamówienia?",
      onConfirm: onCloseConfirm,
      onConfirmText: "Zakończ",
      onConfirmColor: "primary",
    });
  };

  const onCommentConfirm = (cmt) => {
    console.log("OrderDetails onCommentConfirm");
    closeModal();
    const data = {
      author: user.phone,
      authorName: user.name,
      text: cmt,
    };
    setLoading(true);
    console.log("OrderDetails onCommentConfirm start", data);
    axios
      .put("order/" + order.id + "/comment", data)
      .then((res) => {
        console.log("OrderDetails onCommentConfirm res", res);
        setOrder(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("OrderDetails onCommentConfirm err", err);
        error(parseAxiosError(err));
      });
  };

  const onComment = () => {
    console.log("OrderDetails onComment");
    const body = (
      <Col style={{ paddingRight: 15, paddingLeft: 15 }}>
        <Row>Jaki masz komentarz?</Row>
        <Row>
          <Input type="textarea" onChange={(e) => setComment(e.target.value)} />
        </Row>
      </Col>
    );
    setModal({
      isOpen: true,
      title: "Komentarz",
      body: body,
      onConfirm: () => onCommentConfirm(commentRef.current),
      onConfirmText: "Dodaj",
      onConfirmColor: "primary",
    });
  };

  const Comments = ({ comments }) => {
    if (!comments) {
      return;
    }
    return comments
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .map((c, index) => {
        return (
          <ListGroupItem key={index}>
            {c.text}
            <div style={{ textAlign: "right", fontSize: 12 }}>
              <Link to={"/user-details"} state={{ phone: c.author }}>
                {c.authorName}
              </Link>
              {", "}
              {dateStringToStringAgo(c.createdAt)}
            </div>
          </ListGroupItem>
        );
      });
  };

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }}>
      <Row className="mt-lg-3">
        <CustomAlert
          message={message}
          color="danger"
          dismiss={() => setMessage("")}
        />
        <CustomModal
          toggle={closeModal}
          isOpen={modal.isOpen}
          title={modal.title}
          body={modal.body}
          onConfirm={modal.onConfirm}
          onConfirmText={modal.onConfirmText}
          onConfirmColor={modal.onConfirmColor}
        />
        <ListGroup>
          <ListGroupItem>
            <Row className="align-items-center viewport-height">
              <Col md="auto">
                Ostatnia modyfikacja: {dateStringToStringAgo(order.updatedAt)}
              </Col>
              <Col></Col>
              <Col md="auto">
                <CustomButton
                  text={"Przypisz się"}
                  color="primary"
                  onClick={onAssign}
                />
              </Col>
              <Col md="auto">
                <CustomButton
                  text={"Zakończ"}
                  color="warning"
                  onClick={onClose}
                />
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        <Col sm={6}>
          <Row className="mt-lg-4">
            <ListGroup>
              <ListGroupItem>Klient: {userElement(client)}</ListGroupItem>
              <ListGroupItem>
                Telefon: {formatPhoneString(order.client)}
              </ListGroupItem>
              <ListGroupItem>
                Etykieta: {markerIfNull(optionToLabel(client.label))}
              </ListGroupItem>
            </ListGroup>
          </Row>
          <Row className="mt-lg-4">
            <ListGroup>
              <ListGroupItem>Numer: {order.number}</ListGroupItem>
              <ListGroupItem>Opis: {order.description}</ListGroupItem>
              <ListGroupItem>
                Kiedy: {dateStringToStringAgo(order.createdAt)}
              </ListGroupItem>
            </ListGroup>
          </Row>
          <Row className="mt-lg-4">
            <ListGroup>
              <ListGroupItem>
                <Row>
                  <Col>Sprzedawca: {userElement(seller)}</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col>
                    Status: <b>{parseOrderStatus(order.status)}</b>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Row>
          <Row className="mt-lg-4">
            <ListGroup>
              <ListGroupItem>
                <Row className="align-items-center viewport-height">
                  <Col>
                    <b>Komentarze</b>
                  </Col>
                  <Col xs="auto">
                    <CustomButton
                      text={"Dodaj"}
                      color="secondary"
                      onClick={onComment}
                    />
                  </Col>
                </Row>
              </ListGroupItem>
              <Comments comments={order.comments} />
            </ListGroup>
          </Row>
        </Col>
        <Col sm={6}>
          <Row className="mt-lg-4">
            <ImageGallery items={images} showPlayButton={false} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
