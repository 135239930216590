import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser } from "../../utils/AuthDataUtils";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import CustomButton from "../../components/CustomButton";

export default function UserPassword() {
  const user = loadUser();
  console.log("Render UserPassword, user:", user !== null);

  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onNext = () => {
    console.log("UserPassword onNext");

    if (!password) {
      error("Brakuje hasła!");
      return;
    }
    if (password !== passwordConfirmation) {
      error("Hasła nie są takie same!");
      return;
    }

    const data = {
      newPassword: password,
    };

    setMessage("");
    setLoading(true);
    axios
      .put("user/password", data)
      .then(() => navigate(location.state.target))
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <CardBody>
            <CardTitle tag="h5">Nowe hasło</CardTitle>
            <Form>
              <FormGroup>
                <Label for="password">Hasło</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password-confirm">Potwierdź hasło</Label>
                <Input
                  type="password"
                  name="password-confirm"
                  id="password-confirm"
                  autoComplete="new-password"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Row className="align-items-center viewport-height">
              <Col></Col>
              <Col xs="auto">
                <CustomButton
                  text={"Anuluj"}
                  color="secondary"
                  onClick={() => navigate(-1)}
                  loading={loading}
                />
              </Col>
              <Col xs="auto">
                <CustomButton
                  text={"Zapisz"}
                  color="primary"
                  onClick={onNext}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </Col>
  );
}
