import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { parseAxiosError } from "../../utils/AxiosUtils";

import { loadUser, updateUser } from "../../utils/AuthDataUtils";

import { Card, Col, Row } from "reactstrap";

import CustomAlert from "../../components/CustomAlert";
import UserForm from "../../components/UserForm";

export default function UserUpdate() {
  const currentUser = loadUser();
  console.log("Render UserUpdate, user:", currentUser !== null);

  const navigate = useNavigate();
  const location = useLocation();
  const usedUser = location.state.usedUser;

  const [updatedUser, setUpdatedUser] = useState(usedUser);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const error = (message) => {
    setMessage(message);
  };

  const onNext = () => {
    console.log("UserUpdate onNext", updatedUser);

    if (
      !Array.isArray(updatedUser.authorities) ||
      !updatedUser.authorities.length
    ) {
      error("Brakuje uprawnień");
      return;
    }
    if (!updatedUser.name) {
      error("Brakuje imienia i nazwiska!");
      return;
    }
    if (!updatedUser.address) {
      error("Brakuje ulicy i numeru!");
      return;
    }
    if (!updatedUser.city) {
      error("Brakuje miasta!");
      return;
    }
    if (!updatedUser.zip) {
      error("Brakuje kodu pocztowego!");
      return;
    }

    setMessage("");
    setLoading(true);
    axios
      .put("user/" + updatedUser.phone, updatedUser)
      .then((res) => {
        if (res.data.phone === currentUser.phone) {
          updateUser(res.data);
        }
        navigate(location.state.target);
      })
      .catch((err) => error(parseAxiosError(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
      <Row className="mt-lg-3">
        <Card>
          <Row className="mt-2" />
          <CustomAlert
            message={message}
            color="danger"
            dismiss={() => setMessage("")}
          />
          <UserForm
            title={"Zmiana danych"}
            user={usedUser}
            loading={loading}
            onChange={(u) => setUpdatedUser(u)}
            onConfirm={onNext}
            onConfirmText={"Zmień"}
          />
        </Card>
      </Row>
    </Col>
  );
}
